@font-face {
  font-family: "NextArt-Bold";
  font-display: swap;
  src: url("../fonts/next\ art_bold.otf") format("OpenType");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "NextArt-semibold";
  font-display: swap;
  src: url("../fonts/next\ art_semibold.otf") format("OpenType");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "NextArt-regular";
  font-display: swap;
  src: url("../fonts/next\ art_regular.otf") format("OpenType");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "NextArt-thin";
  font-display: swap;
  src: url("../fonts/next\ art_thin.otf") format("OpenType");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "NextArt-light";
  font-display: swap;
  src: url("../fonts/next\ art_light.otf") format("OpenType");
  font-weight: 200;
  font-style: normal;
}

.ql-container {
  background-color: #000;
  color: #6c757d;
}

.ql-editor {
  color: #ffffffb2;
  font-size: 16px;
}

.ql-toolbar {
  //   background-color: #444;
  background-color: #ffffffda;
}

.ql-toolbar button {
  color: #fff;
}

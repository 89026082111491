:root {
  --primary: #eb1616;
  --secondary: #191c24;
  --light: #6c7293;
  --dark: #000000;
}

@import "./styles/fonts.scss";
@import "./styles/styles.scss";
.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

html.not-adminPages {
  font-size: 62.5% !important;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "NextArt-regular", sans-serif !important;
  }

  body {
    font-family: "NextArt-regular", sans-serif !important;
    background-color: $white !important;
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0;
    text-decoration: none;
  }

  svg {
    vertical-align: baseline;
  }

  ul {
    list-style: none;
  }
}

button {
  border: none;
  cursor: pointer;
}

.card-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include laptop {
    justify-content: space-evenly;
  }
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Button ***/
.btn {
  transition: 0.5s;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 50px;
}
.content {
  margin-left: 250px;
  min-height: 100vh;
  background: var(--dark);
  transition: 0.5s;
}

@media (min-width: 992px) {
  .content {
    width: calc(100% - 250px);
  }
  .content.open {
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 991.98px) {
  .content {
    width: 100%;
    margin-left: 0;
  }
}

.content .navbar .navbar-nav .nav-link {
  margin-left: 25px;
  padding: 12px 0;
  color: var(--light);
  outline: none;
}

.content .navbar .navbar-nav .nav-link:hover,
.content .navbar .navbar-nav .nav-link.active {
  color: var(--primary);
}

.content .navbar .sidebar-toggler,
.content .navbar .navbar-nav .nav-link i {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--dark);
  border-radius: 40px;
}

.content .navbar .dropdown-item {
  color: var(--light);
}

.content .navbar .dropdown-item:hover,
.content .navbar .dropdown-item.active {
  background: var(--dark);
}

.content .navbar .dropdown-toggle::after {
  margin-left: 6px;
  vertical-align: middle;
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  transition: 0.5s;
}

.content .navbar .dropdown-toggle[aria-expanded="true"]::after {
  transform: rotate(-180deg);
}

@media (max-width: 575.98px) {
  .content .navbar .navbar-nav .nav-link {
    margin-left: 15px;
  }
}

@media (min-width: 992px) {
  .sidebar {
    margin-left: 0;
  }

  .sidebar.open {
    margin-left: -250px;
  }
}

@media (max-width: 991.98px) {
  .sidebar {
    margin-left: -250px;
  }

  .sidebar.open {
    margin-left: 0;
  }
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}
.tab-content .tab-pane.fade {
  display: none;
}
.tab-content .tab-pane.fade.show.active {
  display: block;
}

.close{
    padding-top: 0;
    padding-bottom: 0;
    font-size: 24px;
}
.progress{
    position: relative;
    width: 100%;
    height: 30px;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;
    border-radius: 1.28rem;
}
.percent{
    position: absolute;
}
.bar{
    background-color: #5bc0de;
    background-image: linear-gradient( 45deg ,rgba(255,255,255,0.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,0.15) 50%,rgba(255,255,255,0.15) 75%,transparent 75%,transparent);
    height: 30px;
}
.btn_success{
    border-color: #1F9D57 !important;
    background-color: #28C76F !important;
    color: #FFFFFF;
    border: 0;
    margin-right: 10px;
}
.fileinput_button{
    position: relative;
}
.btn_success i{
    
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 6px;
}
.fileinput_button input{
    margin: 0px;
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0;
    height: 100%;
    right: 0;
    width: 100%;
}



.media_block .thumb  {
    width: 180px;
    height: 220px;
    float: left;
}
.media_block .thumbnail{
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 46px;
    padding: 4px;
    border: 1px solid #ddd;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 3px rgb(0 0 0 / 6%);
    -moz-box-shadow: 0 1px 3px rgba(0,0,0,0.055);
    box-shadow: 0 1px 3px rgb(0 0 0 / 6%);
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;

}
.media_block .thumbnails>li {
    margin: 0 13px 10px 0;
    position: relative;
}

.media_block input[type="checkbox"] {
    display:none;
}
.media_block .btn_group{
    width: 100%;
    display: flex;
    justify-content: center;
}
.media_block input[type="checkbox"] + label span{
    display:inline-block;
    width:19px;
    height:19px;
    margin:-1px 4px 0 0;
    vertical-align:middle;
    background:url(../../../assets/img/check_radio_sheet.png) left top no-repeat;
    cursor:pointer;
}
.media_block input[type="checkbox"]:checked + label span{
    background:url(../../../assets/img/check_radio_sheet.png) -19px top no-repeat;
}
.media_block .check {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px!important;
}

.media_block .btn {
    font-size: 16px;
    flex: 0;
    padding: 7px 15px;
}
.media_block .toolbar {

}
.media_block .thumb:hover .toolbar {
    display: block;
}
.media_block .thumbnail>img {
    display: block;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    width: 180px;
    height: 180px;
    object-fit: cover;
    object-position: center;
}
.btn_info{
    
    background-color: #00CFE8 !important;
    color: #FFFFFF;
    border: 0;
}